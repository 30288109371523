import {useAuth} from "@clerk/clerk-react";
import {storage} from "~/helpers";
import {authenticationSuccess} from "~/store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {api} from "~/utils/axiosInstances";
import Loading from "~/components/Loading";
import { jwtDecode } from 'jwt-decode';

let authPromise = undefined

export const AuthIntermediator = ({children, ...props}) => {
    const { getToken, isSignedIn, isLoaded} = useAuth()
    const dispatch = useDispatch()
    const user = useSelector((state) => state?.authenticationReducer?.user)

    useEffect(() => {
        if (!isLoaded || !isSignedIn || authPromise || user) {
            return
        }

        authPromise = getToken().then(async (token) => {
            const user = jwtDecode(token)
            storage.setItem('token', user.authToken)
            dispatch(authenticationSuccess({user: (await api.get('users/@me')).data, token: user.authToken}))
        })

    }, [isLoaded, isSignedIn]);

    useEffect(() => {
        if(!user) {
            authPromise = undefined
        }
    }, [user]);

    const ready = useMemo(() => {
        return isLoaded && isSignedIn && !!user
    }, [isLoaded, isSignedIn, user])

    return ready ? children : (<Loading message={'Loading...'} style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0}}/>)
}
